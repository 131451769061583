import { memo, Suspense, useCallback, useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { Loading, LayoutProvider } from '@hu-care/react-layout';
import { Main } from './views/components/main';
import { Route } from 'react-router-dom';
import AuthCallback from './views/pages/auth-callback.page';
import { SkipAllError, useAuth } from './contexts/auth.context';
import { makeClient } from './apollo/client';
import { SharedDialog } from '@hu-care/react-ui-store';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [pathname]);

  return null;
}

export const App = memo(() => {
  const { ready, getToken, refreshToken, token, logout } = useAuth();
  const [apollo, setApollo] = useState<ApolloClient<any> | null>(null);
  const [errored, setErrored] = useState<Error | boolean>(false);

  if (errored) {
    // Big error, catch by global Error boundary, user needs to reload the page
    throw errored;
  }

  const onRefreshFail = useCallback((err?: Error) => {
    if (err && err instanceof SkipAllError) {
      setErrored(err);
    } else {
      logout();
    }
  }, [logout, setErrored]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    setApollo(
      makeClient({
        tokenGetter: getToken,
        refreshToken: () => refreshToken(token?.refreshToken),
        onRefreshFail: onRefreshFail,
      }),
    );
  }, [ready, getToken, refreshToken, token, onRefreshFail]);

  if (!apollo) {
    return null;
  }

  return (
    <ApolloProvider client={apollo}>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/callback">
            <AuthCallback/>
          </Route>
          <LayoutProvider routes={[]}>
            <Main />
            <SharedDialog />
          </LayoutProvider>
        </Switch>
      </Suspense>
    </ApolloProvider>
  );
});
