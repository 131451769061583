import { FC, useMemo, Suspense } from 'react';
import { Switch } from 'react-router';
import { Layout } from './layout/layout';
import { renderCommonRoutes, renderErrorRoutes } from '../../routes';
import { ErrorBoundary } from './error.boundary';
import { Loading } from '@hu-care/react-layout';
import { DownloadWidget } from './download-widget';

export const Main: FC = () => {
  const routes = useMemo(() => renderCommonRoutes().concat(renderErrorRoutes()), []);

  return (
    <Layout>
      <Suspense fallback={<Loading/>}>
        <ErrorBoundary>
          <Switch>
            {routes}
          </Switch>
        </ErrorBoundary>
      </Suspense>
      <DownloadWidget/>
    </Layout>
  );
}
