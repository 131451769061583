import { FC, memo } from 'react';
import {
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
} from '@mui/material';
import { Announcement, Business, Dashboard, Group, OpenInNew, Payment } from '@mui/icons-material';
import { Link as RouteLink } from 'react-router-dom';
import { getRoute, RoutesKeys } from '../../../routes';

interface DividerData {
  divider: true;
}

interface SideBarLinkData {
  label: string;
  href: string;
  icon: any;
  external?: boolean;
  target?: string;
}

type LinkData = DividerData | SideBarLinkData;

const links: LinkData[] = [
  {
    label: 'Dashboard',
    href: getRoute(RoutesKeys.dashboard),
    icon: <Dashboard color="inherit"/>,
  },
  {
    label: 'Professionals',
    href: getRoute(RoutesKeys.professionals),
    icon: <Group color="inherit"/>,
  },
  {
    label: 'Organizations',
    href: getRoute(RoutesKeys.organizations),
    icon: <Business color="inherit"/>,
  },
  {
    label: 'Licenses',
    href: getRoute(RoutesKeys.licenses),
    icon: <Payment color="inherit"/>,
  },
  {
    divider: true,
  },
  {
    label: 'Patients',
    href: getRoute(RoutesKeys.patients),
    icon: <Group color="inherit"/>,
  },
  {
    label: 'Users',
    href: new URL('/admin/users', process.env.REACT_APP_AUTH_URL).toString(),
    icon: <Group color="inherit"/>,
    external: true,
    target: '_blank',
  },
  {
    divider: true,
  },
  {
    label: 'Platform alerts',
    href: getRoute(RoutesKeys.platformAlerts),
    icon: <Announcement color="inherit"/>,
  },
];

const isDivider = (l: LinkData): l is DividerData => 'divider' in l;

const SidebarLink = memo<{ link: LinkData }>(({ link }) => {
  if (isDivider(link)) {
    return (
      <Divider/>
    )
  }

  const { label, href, icon, external, target } = link;

  const content = (
    <ListItem button>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={label}/>
      {external && (
        <ListItemSecondaryAction>
          <ListItemIcon>
            <OpenInNew fontSize="small" color="inherit"/>
          </ListItemIcon>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );

  if (external) {
    return (
      <Link component="a" href={href} target={target} color="inherit">
        {content}
      </Link>
    )
  }

  return (
    <Link component={RouteLink} to={href} color="inherit" underline="none">
      {content}
    </Link>
  )
});

const drawerWidth = 240;

export const Sidebar: FC = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
      }}
      PaperProps={{
        sx: {
          width: drawerWidth,
        },
      }}
    >
      <Toolbar />
      <Box overflow="auto">
        <List>
          {links.map((link, i) => (
            <SidebarLink link={link} key={i}/>
          ))}
        </List>
        <Divider/>
      </Box>
    </Drawer>
  )
}
