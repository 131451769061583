import { memo, useCallback, useEffect, useRef } from 'react';
import { Layout as HuCareLayout, NavBarProps, useLayout } from '@hu-care/react-layout';
import { NavBar } from './nav-bar';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useMeasure } from 'react-use';
import { Sidebar } from './side-bar';

const layoutSxs = {
  root: {
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    zIndex: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    padding: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
}

const landscapeToolbarKey = '@media (min-width:0px) and (orientation: landscape)';
const desktopToolbarKey = '@media (min-width:600px)';

export const Layout = memo(({ children }) => {
  // Track the navbar height, its height can vary because the impersonate bar is not always present
  // We need to add a dynamic "spacer" element to avoid the bar going on top of the content
  const [navBarRef, { height }] = useMeasure<HTMLDivElement>();
  const spacerRef = useRef<HTMLDivElement>(null);

  const navBar = useCallback((props: NavBarProps) => (
    <NavBar {...props} ref={navBarRef}/>
  ), [navBarRef]);

  const { containerProps } = useLayout<{ containerProps?: any }>();
  const theme = useTheme();
  // Calculate the current Toolbar height
  // HucareLayout renders a default Toolbar spacer, we have our spacer
  // we need to subtract the current toolbar height from our spacer
  const toolbarMixin: any = theme.mixins.toolbar;
  const defaultMinHeight = toolbarMixin.minHeight;
  const isLandscape = useMediaQuery(landscapeToolbarKey);
  const isDesktop = useMediaQuery(desktopToolbarKey);

  const toolbarMinHeight = isDesktop
    ? toolbarMixin[desktopToolbarKey].minHeight
    : isLandscape ? toolbarMixin[landscapeToolbarKey].minHeight
      : defaultMinHeight;

  useEffect(() => {
    if (spacerRef.current) {
      spacerRef.current.style.marginBottom = `${height - toolbarMinHeight}px`;
    }
  }, [height, toolbarMinHeight]);

  return (
    <HuCareLayout
      showEnvBar={false}
      navBar={navBar}
      maxWidth="xl"
      {...containerProps}
      className={containerProps?.className}
      sx={layoutSxs.container}
      rootProps={{ sx: layoutSxs.root }}
      mainProps={{ sx: layoutSxs.content }}
    >
      <Box display="flex">
        <Sidebar/>
        <Box width="100%" pt={2} pb={4}>
          {children}
        </Box>
      </Box>
    </HuCareLayout>
  );
});
