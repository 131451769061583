export enum RoutesKeys {
  dashboard = 'dashboard',
  page404 = 'page-not-found',
  page403 = 'page-forbidden',
  professionals = 'professionals',
  professional = 'professional',
  professionalsCreate = 'professionalsCreate',
  licenses = 'licenses',
  license = 'license',
  organizations = 'organizations',
  organization = 'organization',
  patients = 'patients',
  patient = 'patient',
  patientProfessionalsCreate = 'patientProfessionalsCreate',
  platformAlerts = 'platformAlerts',
  platformAlert = 'platformAlert',
}

export interface RouteType {
  key: RoutesKeys;
  path: string;
  component: any;
  exact?: boolean;
}
