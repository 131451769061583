import { RoutesKeys, RouteType } from './interfaces';
import { asyncLoad } from '@hu-care/react-utils';

export const errorRoutes: RouteType[] = [
  {
    key: RoutesKeys.page403,
    path: '/403',
    exact: false,
    component: asyncLoad(() => import('../views/pages/errors/page-403.page')),
  },
  {
    key: RoutesKeys.page404,
    path: '/404',
    exact: false,
    component: asyncLoad(() => import('../views/pages/errors/page-404.page')),
  },
  {
    key: RoutesKeys.page404,
    path: '/*',
    exact: false,
    component: asyncLoad(() => import('../views/pages/errors/page-404.page')),
  },
];
