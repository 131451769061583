import { forwardRef } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { AppBar, Box, Grid, Hidden, Link, Toolbar, Typography } from '@mui/material';
import { NavBarProps } from '@hu-care/react-layout';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { UserMenu } from './user-menu';

export const NavBar = forwardRef<HTMLDivElement, NavBarProps>((props, ref) => {
  const isMobile = useIsMobile('sm');

  return (
    <AppBar position={'fixed'} elevation={0} ref={ref} id="main-header">
      <Toolbar disableGutters sx={{ pt: 0 }}>
        <Box component="nav" width="100%" px={{ sm: 1, md: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={isMobile ? 0 : 2} alignItems="center">
                <Hidden xsDown>
                  <Grid item>
                    <Box display="flex" alignItems="flex-end">
                      <Link component={RouteLink} to="/">
                        <Box
                          component="img"
                          src="/logo_csw_white.svg"
                          alt="App Logo"
                          height={46}
                          width="auto"
                        />
                      </Link>
                      <Box ml={1}>
                        <Typography>
                          Admin
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Hidden>

                <Hidden smUp>
                  <Grid item>
                    <Link component={RouteLink} to="/">
                      <Box
                        component="img"
                        src="/logo_csw_white_small.svg"
                        alt="App Logo"
                        height={34}
                        width="auto"
                      />
                    </Link>
                  </Grid>
                </Hidden>

              </Grid>
            </Grid>

            <Grid item sx={{ flex: 1 }}>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <UserMenu/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  )
});
