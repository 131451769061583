import { errorRoutes } from './error.routes';
import { Route } from 'react-router-dom';
import { getRouteFactory } from '@hu-care/react-utils';
import { RoutesKeys } from './interfaces';
import { routes } from './routes';

export * from './interfaces';

export const allRoutes = [
  ...routes,
  ...errorRoutes,
];

export const renderCommonRoutes = () => (
  routes.map(({ component, exact, path }) => (
    <Route key={path} exact={exact} path={path} component={component}/>
  ))
);

export const renderErrorRoutes = () => (
  errorRoutes.map(({ component, exact, path }) => (
    <Route key={path} exact={exact} path={path} component={component}/>
  ))
);

const routeByKey = allRoutes.reduce((acc, r) => {
  acc.set(r.key, r.path);
  return acc;
}, new Map<RoutesKeys, string>());

export const getRoute = getRouteFactory<RoutesKeys>(routeByKey);
