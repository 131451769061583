import { createContext, FC, useCallback, useContext, useMemo, useReducer } from 'react';
import { uniqueId } from 'lodash-es';
import { useAuth } from './auth.context';

export interface DownloadCtx {
  addDownload: (url: string, label: string) => void;
  removeDownload: (id: string) => void;
  queue: DownloadItem[];
}

const DownloadContext = createContext<DownloadCtx>({
  addDownload: (url: string, label: string) => void 0,
  removeDownload: id => void 0,
  queue: [],
});

export interface DownloadItem {
  id: string;
  state: string;
  label: string;
  downloadUrl?: string;
  abort: AbortController;
}

interface DownloadState {
  downloadQueue: DownloadItem[];
}

type DownloadAction = {
  type: 'DOWNLOAD_START';
  id: string;
  label: string;
  abort: AbortController;
} | {
  type: 'DOWNLOAD_END';
  id: string;
  url: string;
} | {
  type: 'DOWNLOAD_CANCEL';
  id: string;
} | {
  type: 'DOWNLOAD_REMOVE';
  id: string;
} | {
  type: 'DOWNLOAD_ERROR';
  id: string;
}

const reducer = (state: DownloadState, action: DownloadAction) => {
  let idx, newQueue;
  switch (action.type) {
    case 'DOWNLOAD_START':
      return {
        ...state,
        downloadQueue: [
          ...state.downloadQueue,
          {
            id: action.id,
            state: 'downloading',
            label: action.label,
            abort: action.abort,
          },
        ],
      }
    case 'DOWNLOAD_END':
      idx = state.downloadQueue.findIndex(q => q.id === action.id);
      if (idx < 0) {
        return state;
      }
      newQueue = [...state.downloadQueue];
      newQueue[idx].state = 'ready';
      newQueue[idx].downloadUrl = action.url;
      return {
        ...state,
        downloadQueue: newQueue,
      }
    case 'DOWNLOAD_CANCEL':
      idx = state.downloadQueue.findIndex(q => q.id === action.id);
      if (idx < 0) {
        return state;
      }
      newQueue = [...state.downloadQueue];
      newQueue[idx].state = 'cancelled';
      return {
        ...state,
        downloadQueue: newQueue,
      }
    case 'DOWNLOAD_REMOVE':
      newQueue = [...state.downloadQueue].filter(q => q.id !== action.id);
      return {
        ...state,
        downloadQueue: newQueue,
      }
    case 'DOWNLOAD_ERROR':
      idx = state.downloadQueue.findIndex(q => q.id === action.id);
      if (idx < 0) {
        return state;
      }
      newQueue = [...state.downloadQueue];
      newQueue[idx].state = 'error';
      return {
        ...state,
        downloadQueue: newQueue,
      }
  }
}

const initialState: DownloadState = {
  downloadQueue: [],
};

export const DownloadProvider: FC = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, initialState);
  const { getToken } = useAuth();

  const addDownload = useCallback((url: string, label: string) => {
    const id = uniqueId();
    const token = getToken();
    const abort = new AbortController();

    dispatch({
      type: 'DOWNLOAD_START',
      id,
      label,
      abort,
    });

    abort.signal.addEventListener('abort', () => {
      dispatch({
        type: 'DOWNLOAD_CANCEL',
        id,
      });
    });

    fetch(url, {
      signal: abort.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        dispatch({ type: 'DOWNLOAD_END', id, url });
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: 'DOWNLOAD_ERROR', id });
      });
  }, [dispatch, getToken]);

  const removeDownload = useCallback((id: string) => {
    dispatch({
      type: 'DOWNLOAD_REMOVE',
      id,
    });
  }, [dispatch]);

  const value = useMemo<DownloadCtx>(() => ({
    addDownload,
    queue: store.downloadQueue,
    removeDownload,
  }), [addDownload, store, removeDownload]);

  return (
    <DownloadContext.Provider value={value}>
      {children}
    </DownloadContext.Provider>
  )
}

export const useDownload = () => useContext(DownloadContext);
