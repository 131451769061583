import { FC, MouseEvent, useCallback, useState } from 'react';
import { Avatar, Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Theme, Typography } from '@mui/material';
import { AccountCircle, ExitToApp, Person } from '@mui/icons-material';
import { useAuth } from '../../../contexts/auth.context';
import { useIsMobile } from '../../../hooks/useIsMobile';

const avatarSmallSx = {
  height: 32,
  width: 32,
};

const liIconSx = {
  minWidth: (theme: Theme) => theme.spacing(5),
};

export const UserMenu: FC = () => {
  const { logout, user, settingsUrl } = useAuth();
  const isMobile = useIsMobile();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = useCallback((e: MouseEvent) => {
    e.preventDefault();
    logout();
  }, [logout]);

  return (
    <>
      { isMobile
        ? <IconButton
            color="inherit"
            onClick={handleClick}
            sx={{
              color: theme => theme.palette.background.paper,
            }}
          >
          {
            user?.picture
              ? <Avatar src={user?.picture} sx={avatarSmallSx} />
              : <AccountCircle sx={avatarSmallSx} />
          }
        </IconButton>
        : user && (
          <Button
            variant="text"
            color="inherit"
            onClick={handleClick}
            startIcon={
              user?.picture
                ? <Avatar src={user?.picture} sx={avatarSmallSx} />
                : <AccountCircle sx={avatarSmallSx} />
            }
            >
            <Typography
              sx={{
                paddingRight: theme => theme.spacing(1),
                textTransform: 'capitalize',
                fontWeight: theme => theme.typography.fontWeightMedium,
                fontSize: 16,
                color: 'white',
              }}
            >
              {user.name[0]}{'. '}{user.surname}
            </Typography>
        </Button>
        )
      }

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        autoFocus={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minWidth={100}
          p={2}
          sx={{
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <Box pb={1}>
            <Avatar
              src={user?.picture}
              sx={{
                height: 52,
                width: 52,
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            />
          </Box>
          <Typography variant="h6" color="textSecondary">
            {user?.name}{' '}{user?.surname}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {user?.email}
          </Typography>
        </Box>
        <MenuItem component={'a'} href={settingsUrl}>
          <ListItemIcon sx={liIconSx}>
            <Person/>
          </ListItemIcon>
          <Typography variant="body2">Profilo utente</Typography>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon sx={liIconSx}>
            <ExitToApp/>
          </ListItemIcon>
          <Typography variant="body2">Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
