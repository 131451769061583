import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { HttpLink, makeVar, split } from '@apollo/client';

function getUserId() {
  return window.userId;
}

export const currentProfessionalIdVar = makeVar<string | null>(null);

export function httpLink(tokenGetter: () => string) {
  const apiUrl = new URL(process.env.REACT_APP_API_ENDPOINT!);
  apiUrl.pathname = `${apiUrl.pathname === '/' ? '' : apiUrl.pathname}/graphql`;
  const wsUrl = new URL(apiUrl.toString());
  wsUrl.protocol = apiUrl.protocol === 'https:' ? 'wss' : 'ws';

  const httpOpts = {
    uri: apiUrl.toString(),
    credentials: 'include',
    includeExtensions: true,
  }

  // Create all possible http links
  const batchLink = new BatchHttpLink(httpOpts);
  const baseHttpLink = new HttpLink(httpOpts);

  // by default all queries/mutations are batched,
  // optionally setting context.batch to false will disable batch for that specific operation
  return split(
    (op) => op.getContext().batch === false,
    baseHttpLink,
    batchLink,
  );
}

export function authLink(tokenGetter: () => string) {
  return setContext(
    async (data, { headers }) => {
      const currentProfessionalId = currentProfessionalIdVar();
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${await tokenGetter()}`,
          ...process.env.NODE_ENV === 'development' && {
            userId: getUserId(),
          },
          ...currentProfessionalId && {
            'X-CURRENT-PROFESSIONAL-ID': currentProfessionalId,
          },
        },
      }
    },
  );
}
