import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DownloadItem, useDownload } from '../../contexts/download.context';
import {
  AppBar,
  Box, CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import { Cancel, CloudDownload, DeleteForever, Error, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const paperSx: any = {
  position: 'fixed',
  bottom: 0,
  right: 10,
  width: 300,
  maxHeight: 250,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

export const DownloadWidget: FC = () => {
  const { queue, removeDownload } = useDownload();
  const [collapsed, setCollapsed] = useState(false);
  const queueLength = queue.length;
  const open = queueLength > 0;

  const listWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (queueLength && listWrapperRef.current) {
      listWrapperRef.current.scrollTo(0, listWrapperRef.current.clientHeight);
    }
  }, [queueLength]);

  const downloadButton = useCallback((q: DownloadItem) => {
    switch (q.state) {
      case 'downloading':
        return (
          <IconButton onClick={() => q.abort.abort()}>
            <Cancel/>
            <CircularProgress
              sx={{
                position: 'absolute',
              }}
            />
          </IconButton>
        )
      case 'cancelled':
        return (
          <IconButton onClick={() => removeDownload(q.id)}>
            <DeleteForever/>
          </IconButton>
        )
      case 'ready':
        return (
          <IconButton component="a" href={q.downloadUrl} download={q.label}>
            <CloudDownload/>
          </IconButton>
        )
      case 'error':
        return (
          <IconButton onClick={() => removeDownload(q.id)}>
            <Error/>
          </IconButton>
        )
    }
  }, [removeDownload]);

  if (!open) {
    return null;
  }

  return (
    <Paper sx={paperSx}>
      <AppBar position="static" color="secondary" elevation={1}>
        <Toolbar variant="dense">
          <Typography>Downloads</Typography>
          <IconButton size="small" onClick={() => setCollapsed(o => !o)} color="inherit" sx={{ ml: 'auto' }}>
            {collapsed
              ? <KeyboardArrowUp fontSize="inherit" color="inherit"/>
              : <KeyboardArrowDown fontSize="inherit" color="inherit"/>
            }
          </IconButton>
        </Toolbar>
      </AppBar>
      {!collapsed && (
        <Box
          sx={{
            overflowY: 'auto',
          }}
          ref={listWrapperRef}
        >
          <List dense>
            {queue.map(q => (
              <ListItem key={q.id} divider>
                <ListItemText
                  primary={q.label}
                  secondary={q.state}
                  primaryTypographyProps={{
                    sx: {
                      width: '90%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    },
                  }}
                />
                <ListItemSecondaryAction>
                  {downloadButton(q)}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Paper>
  )
}
