import { RoutesKeys, RouteType } from './interfaces';
import { asyncLoad } from '@hu-care/react-utils';

export const routes: RouteType[] = [
  {
    key: RoutesKeys.patient,
    component: asyncLoad(() => import('../views/pages/patient.page')),
    path: '/patients/:id',
    exact: true,
  },
  {
    key: RoutesKeys.patients,
    component: asyncLoad(() => import('../views/pages/patients.page')),
    path: '/patients',
    exact: true,
  },
  {
    key: RoutesKeys.organization,
    component: asyncLoad(() => import('../views/pages/organization.page')),
    path: '/organizations/:id',
    exact: true,
  },
  {
    key: RoutesKeys.organizations,
    component: asyncLoad(() => import('../views/pages/organizations.page')),
    path: '/organizations',
    exact: true,
  },
  {
    key: RoutesKeys.license,
    component: asyncLoad(() => import('../views/pages/license.page')),
    path: '/licenses/:id',
    exact: true,
  },
  {
    key: RoutesKeys.licenses,
    component: asyncLoad(() => import('../views/pages/licenses.page')),
    path: '/licenses',
    exact: true,
  },
  {
    key: RoutesKeys.professionalsCreate,
    component: asyncLoad(() => import('../views/pages/professionals-create.page')),
    path: '/professionals/create',
    exact: true,
  },
  {
    key: RoutesKeys.professional,
    component: asyncLoad(() => import('../views/pages/professional.page')),
    path: '/professionals/:id',
    exact: true,
  },
  {
    key: RoutesKeys.patientProfessionalsCreate,
    component: asyncLoad(() => import('../views/pages/patient-professionals-create.page')),
    path: '/professionals/:id/patients-create',
    exact: true,
  },
  {
    key: RoutesKeys.professionals,
    component: asyncLoad(() => import('../views/pages/professionals.page')),
    path: '/professionals',
    exact: true,
  },
  {
    key: RoutesKeys.platformAlert,
    component: asyncLoad(() => import('../views/pages/platform-alert.page')),
    path: '/platform-alerts/:id',
    exact: true,
  },
  {
    key: RoutesKeys.platformAlerts,
    component: asyncLoad(() => import('../views/pages/platform-alerts.page')),
    path: '/platform-alerts',
    exact: true,
  },
  {
    key: RoutesKeys.dashboard,
    component: asyncLoad(() => import('../views/pages/dashboard.page')),
    path: '/',
    exact: true,
  },
];
